
import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  Box,
  Typography,
  Chip,
  IconButton,
  TextField,
  InputAdornment,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';

// Mock student data with more realistic information
const generateMockData = (selectedClass) => {
  const students = [];
  for (let i = 1; i <= 20; i++) {
    students.push({
      id: i,
      name: `Student ${i}`,
      rollNo: `${selectedClass}-${i.toString().padStart(2, '0')}`,
      tamil: Math.floor(Math.random() * 31) + 70, // 70-100
      english: Math.floor(Math.random() * 31) + 70,
      maths: Math.floor(Math.random() * 31) + 70,
      science: Math.floor(Math.random() * 31) + 70,
      social: Math.floor(Math.random() * 31) + 70,
    });
  }

  students[0].name = "Aswath k";
  students[1].name = "Bharani S";
  students[2].name = "Bhuvi t";
  students[3].name = "Dhviya S";
  students[4].name = "Ealango P";
  students[5].name = "Gokul P";
  students[6].name = "Kavin T";
  students[7].name = "Karthi S";
  students[8].name = "Mohan A";
  students[9].name = "Madhan S";
  students[10].name = "Niranjan L";
  students[11].name = "Nithya K";
  students[12].name = "Praveen T";
  students[13].name = "Prithvi S";
  students[14].name = "Ranjith C";
  students[15].name = "Sakthi P";
  students[16].name = "Srini B";
  students[17].name = "Sampath R";
  students[18].name = "Tamil V";
  students[19].name = "Vel Murugan H";

  return students;
};


const MarksTable = ({ isClassTeacher, selectedClass, selectedSubject }) => {
  const [orderBy, setOrderBy] = useState('name');
  const [order, setOrder] = useState('asc');
  const [searchTerm, setSearchTerm] = useState('');
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(generateMockData(selectedClass));
  }, [selectedClass]);

  const calculateTotal = (student) => {
    return student.tamil + student.english + student.maths + student.science + student.social;
  };

  const calculateGrade = (total) => {
    const average = total / 5;
    if (average >= 90) return 'A+';
    if (average >= 80) return 'A';
    if (average >= 70) return 'B';
    if (average >= 60) return 'C';
    return 'F';
  };

  const getPassFailStatus = (marks) => {
    return marks >= 35 ? (
      <Chip 
        label="PASS" 
        size="small" 
        sx={{ 
          bgcolor: '#4caf50',
          color: 'white',
          fontWeight: 'bold'
        }} 
      />
    ) : (
      <Chip 
        label="FAIL" 
        size="small" 
        sx={{ 
          bgcolor: '#f44336',
          color: 'white',
          fontWeight: 'bold'
        }} 
      />
    );
  };

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const getSortedValue = (student, property) => {
    switch (property) {
      case 'total':
        return calculateTotal(student);
      case 'grade':
        return calculateGrade(calculateTotal(student));
      default:
        return student[property];
    }
  };

  // Calculate rankings based on total marks
  const calculateRanks = (students) => {
    const rankedData = [...students]
      .map(student => ({
        ...student,
        total: calculateTotal(student),
      }))
      .sort((a, b) => b.total - a.total); // Sort in descending order of total marks

    // Assign ranks, accounting for ties
    let rank = 1;
    let previousTotal = null;
    rankedData.forEach((student, index) => {
      if (student.total !== previousTotal) {
        rank = index + 1; // Update rank only if total is different
      }
      student.rank = rank;
      previousTotal = student.total;
    });

    return rankedData;
  };

  const rankedData = calculateRanks(data);

  const filteredData = rankedData
    .filter(student => 
      student.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      student.rollNo.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      let compareA = getSortedValue(a, orderBy);
      let compareB = getSortedValue(b, orderBy);

      // Handle grade sorting
      if (orderBy === 'grade') {
        const gradeOrder = { 'A+': 5, 'A': 4, 'B': 3, 'C': 2, 'F': 1 };
        compareA = gradeOrder[compareA];
        compareB = gradeOrder[compareB];
      }

      if (order === 'desc') {
        [compareA, compareB] = [compareB, compareA];
      }

      return compareA < compareB ? -1 : compareA > compareB ? 1 : 0;
    });

  const renderSortableHeader = (label, property) => (
    <TableCell align="center">
      <TableSortLabel
        active={orderBy === property}
        direction={orderBy === property ? order : 'asc'}
        onClick={() => handleSort(property)}
      >
        {label}
      </TableSortLabel>
    </TableCell>
  );

  return (
    <Box>
      <Box sx={{ 
        mb: { xs: 2, md: 3 }, 
        display: 'flex', 
        flexDirection: { xs: 'column', md: 'row' },
        gap: { xs: 2, md: 0 },
        justifyContent: 'space-between', 
        alignItems: { xs: 'stretch', md: 'center' }
      }}>
        <Typography 
          variant="h6" 
          component="div"
          sx={{
            fontSize: { xs: '1rem', md: '1.25rem' }
          }}
        >
          {isClassTeacher ? 
            `All Subjects - ${selectedClass} (Class Teacher View)` :
            `${selectedSubject} - ${selectedClass}`
          }
        </Typography>
        <TextField
          size="small"
          placeholder="Search students..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ 
            width: { xs: '100%', md: '300px' },
            '& .MuiOutlinedInput-root': {
              bgcolor: '#ffffff'
            }
          }}
        />
      </Box>

      <TableContainer 
        component={Paper} 
        sx={{ 
          maxHeight: { xs: 'calc(100vh - 300px)', md: '600px' },
          overflow: 'auto',
          '& .MuiTable-root': {
            minWidth: isClassTeacher ? 900 : 400,
          }
        }}
      >
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              {renderSortableHeader('Name', 'name')}
              {renderSortableHeader('Roll No', 'rollNo')}
              {isClassTeacher ? (
                <>
                  {renderSortableHeader('Tamil', 'tamil')}
                  {renderSortableHeader('English', 'english')}
                  {renderSortableHeader('Maths', 'maths')}
                  {renderSortableHeader('Science', 'science')}
                  {renderSortableHeader('Social', 'social')}
                  {renderSortableHeader('Total', 'total')}
                  {renderSortableHeader('Grade', 'grade')}
                  {renderSortableHeader('Rank', 'rank')}
                </>
              ) : (
                <>
                  {renderSortableHeader('Marks', selectedSubject.toLowerCase())}
                  <TableCell align="center">Status</TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((student) => (
              <TableRow 
                key={student.id}
                sx={{ 
                  '&:nth-of-type(odd)': { backgroundColor: 'rgba(0, 0, 0, 0.02)' },
                  '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
                  '& td': {
                    px: { xs: 1, md: 2 },
                    py: { xs: 1, md: 1.5 },
                    fontSize: { xs: '0.875rem', md: '1rem' }
                  }
                }}
              >
                <TableCell>{student.name}</TableCell>
                <TableCell>{student.rollNo}</TableCell>
                {isClassTeacher ? (
                  <>
                    <TableCell align="center">{student.tamil}</TableCell>
                    <TableCell align="center">{student.english}</TableCell>
                    <TableCell align="center">{student.maths}</TableCell>
                    <TableCell align="center">{student.science}</TableCell>
                    <TableCell align="center">{student.social}</TableCell>
                    <TableCell 
                      align="center" 
                      sx={{ 
                        fontWeight: 'bold',
                        color: theme => theme.palette.primary.main
                      }}
                    >
                      {calculateTotal(student)}
                    </TableCell>
                    <TableCell align="center">
                      <Chip 
                        label={calculateGrade(calculateTotal(student))}
                        size="small"
                        sx={{ 
                          bgcolor: theme => {
                            const grade = calculateGrade(calculateTotal(student));
                            switch(grade) {
                              case 'A+': return '#4caf50';
                              case 'A': return '#8bc34a';
                              case 'B': return '#ffc107';
                              case 'C': return '#ff9800';
                              default: return '#f44336';
                            }
                          },
                          color: 'white',
                          fontWeight: 'bold',
                          fontSize: { xs: '0.75rem', md: '0.875rem' }
                        }}
                      />
                    </TableCell>
                    <TableCell 
                      align="center"
                      sx={{
                        fontWeight: 'bold',
                        color: theme => theme.palette.secondary.main
                      }}
                    >
                      {student.rank}
                    </TableCell>
                  </>
                ) : (
                  <>
                    <TableCell align="center">
                      {student[selectedSubject.toLowerCase()]}
                    </TableCell>
                    <TableCell align="center">
                      {getPassFailStatus(student[selectedSubject.toLowerCase()])}
                    </TableCell>
                  </>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default MarksTable;
