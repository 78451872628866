import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Grid, 
  Paper, 
  Typography,
  Avatar,
  Button,
  Card,
  CardContent,
  Chip,
  IconButton,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MarksTable from './MarksTable';
import LogoutIcon from '@mui/icons-material/Logout';
import ClassIcon from '@mui/icons-material/Class';
import SubjectIcon from '@mui/icons-material/Subject';
import PersonIcon from '@mui/icons-material/Person';
import staffImage from './staff.png'; // Update the path based on your folder structure

const Dashboard = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [teacher, setTeacher] = useState(null);
  const [selectedClass, setSelectedClass] = useState('');
  const [selectedSubject, setSelectedSubject] = useState('');
  
  useEffect(() => {
    const teacherData = localStorage.getItem('teacher');
    if (!teacherData) {
      navigate('/');
      return;
    }
    setTeacher(JSON.parse(teacherData));
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem('teacher');
    navigate('/');
  };

  const handleClassSelect = (className) => {
    setSelectedClass(className);
    // If teacher is class teacher of this class, automatically show all subjects
    if (teacher.classTeacherOf === className) {
      setSelectedSubject('all');
    } else {
      setSelectedSubject(''); // Reset subject selection for non-class teacher
    }
  };

  const handleSubjectSelect = (subject) => {
    setSelectedSubject(subject);
  };

  if (!teacher) return null;

  const getAvailableSubjects = () => {
    if (!selectedClass) return [];
    return teacher.subjects[selectedClass] || [];
  };

  const isClassTeacherOfSelectedClass = teacher.classTeacherOf === selectedClass;

  return (
    <Box sx={{ 
      minHeight: '100vh',
      backgroundColor: '#f0f2f5',
      p: { xs: 1, sm: 2, md: 3 } // Responsive padding
    }}>
      <Grid container spacing={{ xs: 2, md: 3 }}>
        {/* Teacher Profile Section */}
        <Grid item xs={12} md={3}>
          <Paper 
            elevation={3} 
            sx={{ 
              p: { xs: 2, md: 3 },
              background: 'linear-gradient(135deg, #f5f7fa 0%, #e4e8eb 100%)',
              color: '#2c3e50',
              mb: { xs: 2, md: 0 } // Add margin bottom on mobile
            }}
          >
            <Box sx={{ 
              display: 'flex', 
              flexDirection: { xs: 'row', md: 'column' }, 
              alignItems: 'center',
              gap: { xs: 2, md: 0 }
            }}>
              <Avatar
                src={staffImage}
                sx={{ 
                  width: { xs: 80, md: 120 }, 
                  height: { xs: 80, md: 120 }, 
                  mb: { xs: 0, md: 2 },
                  border: '4px solid white',
                  boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
                }}
              />
              <Box sx={{ 
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: { xs: 'flex-start', md: 'center' }
              }}>
                <Typography 
                  variant="h5" 
                  sx={{ 
                    mb: 1,
                    fontSize: { xs: '1.2rem', md: '1.5rem' }
                  }}
                >
                  {teacher.name}
                </Typography>
                <Typography 
                  variant="body1" 
                  sx={{ 
                    mb: { xs: 1, md: 2 },
                    color: '#546e7a'
                  }}
                >
                  ID: {teacher.id}
                </Typography>
                <Chip 
                  icon={<PersonIcon />}
                  label={teacher.isClassTeacher ? `Class Teacher of ${teacher.classTeacherOf}` : 'Subject Teacher'}
                  sx={{ 
                    bgcolor: '#e3f2fd',
                    color: '#1976d2',
                    mb: { xs: 1, md: 2 },
                    '& .MuiChip-icon': {
                      color: '#1976d2'
                    }
                  }}
                />
                <Button 
                  variant="contained" 
                  color="primary"
                  startIcon={<LogoutIcon />}
                  onClick={handleLogout}
                  sx={{ 
                    mt: { xs: 0, md: 2 },
                    bgcolor: '#ef5350',
                    '&:hover': {
                      bgcolor: '#d32f2f'
                    }
                  }}
                >
                  Logout
                </Button>
              </Box>
            </Box>
          </Paper>
        </Grid>

        {/* Main Content Section */}
        <Grid item xs={12} md={9}>
          {/* Classes Row */}
          <Paper 
            elevation={3} 
            sx={{ 
              p: { xs: 1.5, md: 2 }, 
              mb: { xs: 2, md: 3 }
            }}
          >
            <Typography 
              variant="h6" 
              sx={{ 
                mb: 2,
                fontSize: { xs: '1.1rem', md: '1.25rem' }
              }}
            >
              <ClassIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
              Select Class
            </Typography>
            <Box sx={{ 
              display: 'flex', 
              gap: { xs: 1, md: 2 }, 
              flexWrap: 'wrap'
            }}>
              {teacher.classesHandled.map((cls) => (
                <Button
                  key={cls}
                  variant={selectedClass === cls ? "contained" : "outlined"}
                  onClick={() => handleClassSelect(cls)}
                  sx={{ 
                    minWidth: { xs: '80px', md: '100px' },
                    fontSize: { xs: '0.875rem', md: '1rem' },
                    bgcolor: selectedClass === cls ? 'primary.main' : 'transparent'
                  }}
                >
                  {cls}
                </Button>
              ))}
            </Box>
          </Paper>

          {/* Subjects Row - Only show for non-class teachers */}
          {selectedClass && !isClassTeacherOfSelectedClass && (
            <Paper 
              elevation={3} 
              sx={{ 
                p: { xs: 1.5, md: 2 }, 
                mb: { xs: 2, md: 3 }
              }}
            >
              <Typography 
                variant="h6" 
                sx={{ 
                  mb: 2,
                  fontSize: { xs: '1.1rem', md: '1.25rem' }
                }}
              >
                <SubjectIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
                Select Subject for {selectedClass}
              </Typography>
              <Box sx={{ 
                display: 'flex', 
                gap: { xs: 1, md: 2 }, 
                flexWrap: 'wrap'
              }}>
                {getAvailableSubjects().map((subject) => (
                  <Button
                    key={subject}
                    variant={selectedSubject === subject ? "contained" : "outlined"}
                    onClick={() => handleSubjectSelect(subject)}
                    sx={{ 
                      minWidth: { xs: '100px', md: '120px' },
                      fontSize: { xs: '0.875rem', md: '1rem' },
                      bgcolor: selectedSubject === subject ? 'secondary.main' : 'transparent'
                    }}
                  >
                    {subject}
                  </Button>
                ))}
              </Box>
            </Paper>
          )}

          {/* Marks Table */}
          {selectedClass && (isClassTeacherOfSelectedClass || selectedSubject) && (
            <Paper 
              elevation={3} 
              sx={{ 
                p: { xs: 1, md: 2 },
                overflow: 'hidden'
              }}
            >
              <MarksTable 
                isClassTeacher={isClassTeacherOfSelectedClass}
                selectedClass={selectedClass}
                selectedSubject={selectedSubject}
              />
            </Paper>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;